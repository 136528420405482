import React, { useState } from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment"
import BlogSection from "@Components/AdviceCentre/BlogSection/BlogSection"
import News from "@Components/AdviceCentre/News/News"
import BreadcrumbPage from "@Components/BreadcrumbsHome.js";
const BlogDetails = (props) => {
  const GET_BLOG_DETAILS = gql`
  query GetBLog($URL: String!){	
      blogs(where:{URL: $URL}) {
        Category
        Date
        Meta_Description
        Meta_Title
        Modules {
          ... on ComponentModuleContentBlock {
            id
            Add_Blocks {
              Content
              Image {
                alternativeText
                url
              }
            }
          }
        }
        Tail_Image {
          alternativeText
          url
    
        }
        Publish
        Title
        URL
  }
}
`;

  const { loading, error, data } = useQuery(GET_BLOG_DETAILS, {
    variables: { URL: props.slug }
  });
  let shareurl = '';

  if (typeof window !== "undefined") {
      shareurl = window.location.href ? window.location.href : '';
  }



  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading"><div className="dot-pulse"></div></div>
      </Container>
    </div>);
  return (
    <Layout>
      {data?.blogs.map((Page, i) => {
        return (
          <>
            <SEO title={Page ? Page.Meta_Title : ''} description={"Read about"+ Page.Meta_Title +"here and subscribe to stay updated about the happenings at Allsopp & Allsopp Recruitment."} />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template`
              }}
            />
            <Container>
              <BlogSection
              page={Page}
              />
            </Container>


          </>
        )
      })}
      <News CurrentNews={data?.blogs[0].Title} />
      <BreadcrumbPage Page={data?.blogs[0].Title} class="static" alias={'blogs'} type="details-page" />
    </Layout>
  );
}



export default BlogDetails