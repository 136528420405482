/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";

const Layout = ({ children, css, Modules, contact}, props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={data.site.siteMetadata === 'Home' ? 'homepage' : 'inner-page'} >
      {/* {JSON.stringify(Modules)} */}
      {Modules && Modules?.length > 0 ? Modules.map((Module, i) => {
        return (
          <div key={i}>
            {Module.Banner_Title && i === 0 &&
              <Header contact={contact} nobanner={false} siteTitle={data.site.siteMetadata?.title || `Title`} />
            }
            {!Module.Banner_Title && i === 0 &&
                <Header contact={contact} nobanner={true} siteTitle={data.site.siteMetadata?.title || `Title`} />
            }
          </div>
        )
      }) : <Header contact={contact} nobanner={true} siteTitle={data.site.siteMetadata?.title || `Title`} />}
      <main className="main-layout-content">{children}</main>
      <Footer />
    </div >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.string.isRequired,
  Modules: PropTypes.array.isRequired,
  contact: PropTypes.array.isRequired
}

export default Layout
